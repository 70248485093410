<template>
    <div>
        <file-pond
            name="logo"
            ref="pond"
            label-idle="Clicca o trascina qui il logo…"
            accepted-file-types="image/gif, image/jpeg, image/png"
            credits="false"
            :allow-multiple="false"
            :files="uploads"
            :server="serverUrl"
            @processfile="handleProcessFile"
            @removefile="handleRemoveFile"
        />
    </div>
</template>

<script>
    import VueFilePond from 'vue-filepond';
    import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
    import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

    import 'filepond/dist/filepond.min.css';
    import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

    const FilePond = VueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);

    export default {
        data() {
            return {
                uploads: [],
                serverUrl: `${process.env.VUE_APP_SURVEY_REST}/api/v1/logos`
            };
        },

        props: {
            //
        },

        methods: {
            handleProcessFile() {
                this.$emit('logo-uploaded', this.$refs.pond.getFiles()[0].serverId);
            },

            handleRemoveFile() {
                this.$emit('logo-removed');
            }
        },

        components: {
            FilePond
        }
    };
</script>

<style scoped>
</style>
