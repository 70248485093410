<template>
    <div class="descrizione-rete-container">
        <div class="container-fluid full-height">
            <div class="row row-height">
                <div class="col-lg-4 content-left">
                    <div class="content-left-wrapper">
                        <!--            <a href="/" id="logo"><img src="../assets/logo_negativo.png" alt="" width="175"></a>-->
                        <SocialIcons></SocialIcons>
                        <div v-if="step <= 2">
                            <figure><img src="../assets/logo_negativo.png" alt="" class="img-fluid"></figure>
                            <h2 class="mt-4">
                                Anagrafica Impresa Capofila o Referente
                            </h2>
                            <p>
                                Inserisci i dati per identificare l'Impresa Capofila o Referente della Rete
                            </p>
                        </div>
                        <div v-if="step > 2">
                            <figure><img src="../assets/logo_negativo.png" alt="" class="img-fluid"></figure>
                            <h2 class="mt-4">
                                Descrizione Rete
                            </h2>
                            <p>
                                Compila i seguenti campi per specificare le caratteristiche principali della tua Rete.
                            </p>
                        </div>
                        <div class="copy">© 2020 RetImpresa Reference</div>
                    </div>
                </div>
                <!-- /content-left -->

                <div class="col-lg-8 content-right" id="start">
                    <div id="wizard_container">
                        <div id="middle-wizard">
                            <div class="step">
                                <div v-if="step === 0">
                                    <h3 class="main_question wizard-header">
                                        <strong>{{step+1}}/{{stepMax+1}}</strong>
                                        Compila i seguenti campi per completare la sezione</h3>
                                    <div class="form-group">
                                        <input
                                                class="form-control required"
                                                placeholder="Denominazione / Ragione Sociale"
                                                v-model="extraFields.referente.denominazione"
                                        >
                                    </div>
                                    <div class="form-group">
                                        <input
                                                class="form-control required"
                                                placeholder="Partita IVA"
                                                v-model="extraFields.referente.piva"
                                        >
                                    </div>
                                    <div class="form-group">
                                        <input
                                                class="form-control required"
                                                placeholder="Settore Attività"
                                                v-model="extraFields.referente.settore"
                                        >
                                    </div>
                                    <div class="form-group">
                                        <input
                                                class="form-control required"
                                                type="number"
                                                placeholder="Fatturato risultante dall'ultimo esercizio disponibile"
                                                v-model="extraFields.referente.fatturato"
                                        >
                                        <div class="form-text text-muted small d-flex align-items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="20"><path d="M9.5 16A6.61 6.61 0 013 9.5 6.61 6.61 0 019.5 3 6.61 6.61 0 0116 9.5 6.63 6.63 0 019.5 16zm0-14A7.5 7.5 0 1017 9.5 7.5 7.5 0 009.5 2zm.5 6v4.08h1V13H8.07v-.92H9V9H8V8zM9 6h1v1H9z" fill="currentColor"/></svg>
                                            Indicare in milioni
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <input
                                                class="form-control required"
                                                type="number"
                                                placeholder="Numero Dipendenti"
                                                v-model="extraFields.referente.dipendenti"
                                        >
                                    </div>
                                </div>

                                <!-- Anni Settore -->

                                <div v-if="step === 1">
                                    <h3 class="main_question">
                                        <strong>{{step+1}}/{{stepMax+1}}</strong>
                                        Da quanti anni l'azienda opera nel settore?</h3>
                                    <label v-for="(item, idx) in anniSettore" class="container_radio version_2" v-bind:key="idx">{{item}}
                                        <input
                                                type="radio"
                                                name="anni_settore"
                                                id="anni_settore"
                                                v-bind:value="item"
                                                class="required"
                                                v-on:change="setValue('referente.anniSettore', item)"
                                        >
                                        <span class="checkmark"></span>
                                    </label>
                                </div>

<!--                                &lt;!&ndash; Posizionamento &ndash;&gt;-->

<!--                                <div v-if="step === 2">-->
<!--                                    <h3 class="main_question"><strong>{{step+1}}/{{stepMax+1}}</strong>Qual è il posizionamento dell’impresa nel mercato di riferimento (quota percentuale)?</h3>-->
<!--                                    <label v-for="(item, idx) in posizionamenti" class="container_radio version_2" v-bind:key="idx">{{item}}-->
<!--                                        <input-->
<!--                                                type="radio"-->
<!--                                                name="posizionamento"-->
<!--                                                id="posizionamento"-->
<!--                                                v-bind:value="item"-->
<!--                                                class="required"-->
<!--                                                v-on:change="setValue('referente.posizionamento', item)"-->
<!--                                        >-->
<!--                                        <span class="checkmark"></span>-->
<!--                                    </label>-->
<!--                                </div>-->

<!--                                Tipologia Rete -->
                                <div v-if="step === 2">
                                    <div class="mb-2 impresa-aderente-container-fields form-group">
                                        <h3 class="main_question">
                                            <strong>{{step+1}}/{{stepMax+1}}</strong>
                                            Denominazione della Rete</h3>
                                        <input
                                                class="form-control required"
                                                placeholder="Inserisci il nome della Rete"
                                                v-model="extraFields.denominazione"
                                        >
                                    </div>
                                    <div class="mt-4 logo-rete">
                                        <label>Carica il logo della Rete (facoltativo)</label>
                                        <UploadLogo @logo-uploaded="logoUploaded" @logo-removed="logoRemoved"/>
                                    </div>
                                    <div class="mt-5 tipologia-rete">
                                        <h3 class="main_question">
                                            Tipologia Rete:</h3>
                                        <label v-for="(item, idx) in tipologiaRete" class="container_radio version_2" v-bind:key="idx">{{item.value}} {{ item.note }}
                                            <input
                                                    type="radio"
                                                    name="tipologiaRete"
                                                    id="tipologiaRete"
                                                    v-bind:value="item.value"
                                                    class="required"
                                                    v-on:change="setValue('tiplogiaRete', item.value)"
                                            >
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>

                                    <div class="mt-5 tipo-rete">
                                        <h3 class="main_question">
                                            La vostra Rete è:</h3>
                                        <label v-for="(item, idx) in naturaGiuridicaRete" class="container_radio version_2" v-bind:key="idx">{{item.value}}
                                            <input
                                                    type="radio"
                                                    name="naturaGiuridicaRete"
                                                    id="naturaGiuridicaRete"
                                                    v-bind:value="item.value"
                                                    class="required"
                                                    v-on:change="setValue('naturaGiuridicaRete', item.value)"
                                            >
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>

<!--                                Imprese Aderenti -->
                                <div v-if="step === 3">
                                    <div class="mb-2 impresa-aderente-container-fields form-group">
                                        <h3 class="main_question">
                                            <strong>{{step+1}}/{{stepMax+1}}</strong>
                                            Da quante imprese è composta la tua rete</h3>
                                        <input
                                                class="form-control required"
                                                type="number"
                                                placeholder="Inserisci il numero di imprese"
                                                v-model="extraFields.countImprese"
                                        >
                                    </div>
                                    <h3 class="main_question">
                                        Imprese Aderenti:</h3>
                                    <p>Aggiungi di seguito i dati delle altre imprese aderenti (oltre alla capofila/referente)</p>
                                    <div class="impresa-aderente-container-fields form-group">
                                        <input
                                                class="form-control required"
                                                placeholder="Denominazione/ragione sociale"
                                                v-model="temporary.denominazione"
                                        >
                                        <input
                                                class="form-control required"
                                                placeholder="Partita IVA"
                                                v-model="temporary.piva"
                                        >
                                        <input
                                                class="form-control required"
                                                placeholder="Settore di attività"
                                                v-model="temporary.settore"
                                        >
                                    </div>
                                    <div v-if="extraFields.countImprese > 0 && ( (extraFields.impreseAderenti.length + 1) < extraFields.countImprese) ">
                                        <button v-on:click="addImpresa()" class="forward"><i class="fa fa-home" /> Aggiungi Impresa</button>
                                    </div>
                                    <div class="imprese-overview">
                                        <h3 v-if="extraFields.impreseAderenti.length" class="mt-4 main_question">Elenco imprese salvate:</h3>
                                        <ul>
                                            <li v-for="(impresa, idx) in extraFields.impreseAderenti" v-bind:key="idx" class="mt-2">
                                                <a href="#" v-on:click="deleteImpresa(impresa.denominazione)"><span class="delete-impresa">X</span></a>
                                                <strong>{{impresa.denominazione}}</strong>
                                                {{ impresa.piva }} - {{ impresa.settore }}
                                            </li>
                                        </ul>
                                    </div>
                                </div>

<!--                                Conclusione Descrizione di Base -->
                                <div v-if="step === 4">
                                    <h3 class="main_question wizard-header">
                                        <strong>{{step+1}}/{{stepMax+1}}</strong>
                                        Completa le informazioni sulla tua Rete</h3>
                                    <div class="form-group">
                                        <input
                                                class="form-control required"
                                                type="number"
                                                placeholder="Durata del Contratto di Rete"
                                                v-model="extraFields.durataContratto"
                                        >
                                    </div>
                                    <div class="form-group">
                                        <input
                                                class="form-control required"
                                                type="number"
                                                placeholder="Anno di avvio dell’attività di Rete"
                                                v-model="extraFields.annoInzioRete"
                                        >
                                    </div>
                                    <div>
                                        <h3 class="main_question">
                                            La rete formalizza precedenti rapporti di collaborazione tra i partecipanti?</h3>
                                        <label v-for="(item, idx) in ['Si', 'No']" class="container_radio version_2" v-bind:key="idx">{{item}}
                                            <input
                                                    type="radio"
                                                    name="collaborazioni"
                                                    id="collaborazioni"
                                                    v-bind:value="item"
                                                    class="required"
                                                    v-on:change="setValue('collaborazioni', item)"
                                            >
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div>
                                        <h3 class="main_question">
                                            Adesione a RetImpresa</h3>
                                        <label v-for="(item, idx) in ['Si', 'No']" class="container_radio version_2" v-bind:key="idx">{{item}}
                                            <input
                                                    type="radio"
                                                    name="adesioneRetImpresa"
                                                    id="adesioneRetImpresa"
                                                    v-bind:value="item"
                                                    class="required"
                                                    v-on:change="setValue('adesioneRetImpresa', item)"
                                            >
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- /middle-wizard -->
                        <div id="bottom-wizard">
                            <button v-if="step > 0" type="button" name="backward" class="backward" v-on:click="previousStep()">Precedente</button>
                            <button v-if="step !== stepMax" v-on:click="nextStep()" class="forward">Avanti <span v-if="error" class="error error-forward" v-html="error"></span></button>
                            <button v-if="step === stepMax" v-on:click="startSurvey()" class="forward">Inizia Questionario <span v-if="error" class="error error-forward" v-html="error"></span></button>
                        </div>
                        <!-- /bottom-wizard -->
                    </div>
                </div>
                <!-- /content-right-->
            </div>
            <!-- /row-->
        </div>
        <!-- /container-fluid -->
    </div>
</template>

<script>
  import { Component, Vue } from 'vue-property-decorator';
  import UploadLogo from "@/components/UploadLogo";
  import SocialIcons from "@/components/SocialIcons";
  import * as _ from 'lodash';

  @Component({
    components: {
      SocialIcons,
      UploadLogo
    },
  })

  export default class DescrizioneRete extends Vue {
    extraFields = {
      impreseAderenti: [],
      referente: {},
      countImprese: 0
    };

    temporary = {};

    step = 0;

    stepMax = 4;

    impreseAderenti = 1;

    anniSettore = [
      "Meno di 3 (start-up)",
      "da 3 a 7",
      "da 7 a 15 anni",
      "Oltre 15 anni"
    ];

    posizionamenti = [
      "Fino al 5%",
      "dal 5 al 10%",
      "dal 10% al 30%",
      "dal 30% al 50%",
      "oltre 50%"
    ];

    tipologiaRete = [
      {value: "Verticale", note: "(imprese della stessa filiera produttiva, es. subfornitura, filiera, economia di scopo);"},
      {value: "Orizzontale", note: "(imprese del medesimo settore o di filiere differenti, es. accordi commerciali, economia di scala),"},
      {value: "Mista", note: "(con elementi di rete verticale e orizzontale)"}
    ];

    naturaGiuridicaRete = [
      {value: 'Rete contratto'},
      {value: 'Rete soggetto'},
    ];

    error = '';

    async mounted() {
      //
    }


    /**
     * Procede negli step di selezione degli obbiettivi
     * @return {null}
     */
    nextStep() {
      if(this.step === 0)
        if(!this.extraFields.referente.denominazione ||
        !this.extraFields.referente.piva ||
        !this.extraFields.referente.settore ||
        !this.extraFields.referente.fatturato ||
        !this.extraFields.referente.dipendenti) {
          this.error = 'Completa tutti i campi'
          return;
        }
      if(this.step === 1)
        if(!this.extraFields.referente.anniSettore) {
          this.error = 'Seleziona una risposta';
          return;
        }
      // if(this.step === 2)
      //   if(!this.extraFields.referente.posizionamento) {
      //     this.error = 'Seleziona una risposta';
      //     return;
      //   }
      if(this.step === 2)
        if(!this.extraFields.denominazione || !this.extraFields.tiplogiaRete) {
          this.error = 'Completa la sezione';
          return;
        }
      if(this.step === 3)
        if(this.extraFields.countImprese != (this.extraFields.impreseAderenti.length + 1 )) {
          this.error = 'Inserisci il corretto numero di imprese';
          return;
        }

      this.step += 1;
        this.error = '';
    }

    previousStep() {
      this.step -= 1;
    }

    setValue(key, value) {
      if(!key.includes('.'))
        this.extraFields[`${key}`] = value;
      else
        this.extraFields[`${key.split('.')[0]}`][`${key.split('.')[1]}`] = value
    }

    addImpresa() {
      this.extraFields.impreseAderenti.push(this.temporary)
      this.temporary = {};
    }


    deleteImpresa(denominazione) {
      this.extraFields.impreseAderenti = this.extraFields.impreseAderenti.filter( d => d.denominazione !== denominazione);
    }

    /**
     * Set Goal on state and start Survey
     */
    startSurvey() {
      if(this.step === 4)
        if(!this.extraFields.durataContratto ||
          !this.extraFields.annoInzioRete ||
          !this.extraFields.collaborazioni ||
          !this.extraFields.adesioneRetImpresa) {
          this.error = 'Completa la sezione';
          return;
        }
      this.$store.dispatch('setExtraFields', this.extraFields);
    }

    /**
     * A logo has been uploaded.
     */
    logoUploaded(filename) {
        this.extraFields.logoPath = filename;
    }

    /**
     * A logo has been removed.
     */
    logoRemoved() {
        this.extraFields.logoPath = null;
    }
  }
</script>

<style scoped>
 .delete-impresa {
     margin: 10px;
     color: white;
     background: #882424;
     padding: 2px 4px;
     border-radius: 2px;
 }
</style>
